// ES: This component was 99% AI generated using CodeGPT
// Layout/design is very basic/minimal and should be updated at some point,
// but it provides essential dev/debug functionality for Assistant Thread config/management
import React, { Component } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { DEFAULT_VINNIE_OPTIONS } from '@core/models/AIPrompt';
import Deal from '@core/models/Deal';

import { Button, Switch } from '@components/dmp';

import API from '@root/ApiClient';

class AIThreadView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      messages: [],
      thread: null,
      vinnieOptions: _.cloneDeep(DEFAULT_VINNIE_OPTIONS),
    };
    this.messageEndRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
    this.loadThread();
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate(prevProps) {
    const { deal } = this.props;
    if (deal !== prevProps.deal) {
      this.loadThread();
    }
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    if (this.messageEndRef.current) {
      this.messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  handleKeyDown = (event) => {
    if (event.ctrlKey && event.key === 'd') {
      event.preventDefault();
      this.toggleExpanded();
      this.loadThread();
    }
  };

  toggleExpanded = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  };

  loadThread = async () => {
    const { deal, isBart } = this.props;

    // If there's an AI thread associated with the doc, load messages and display for debug
    if (deal.info.threadID) {
      const { thread, messages } = await API.call('loadThreadMessages', { dealID: deal.dealID, isBart });
      this.setState({ thread, messages });
    }
  };

  startThread = () => {
    const { deal } = this.props;
    const { vinnieOptions } = this.state;

    // Note, we don't need to await or handle the response,
    // because starting a new thread saves the threadID on the Deal, which triggers a re-render and reloads the latest messages
    API.call('startThread', { dealID: deal.dealID, vinnieOptions });
  };

  deleteThread = async () => {
    const { deal } = this.props;
    const response = await API.call('deleteThread', { dealID: deal.dealID });
    this.setState({ thread: null, messages: [] });
  };

  handleSwitchChange = (option) => {
    this.setState((prevState) => ({
      vinnieOptions: {
        ...prevState.vinnieOptions,
        [option]: prevState.vinnieOptions[option] === 'true' ? 'false' : 'true',
      },
    }));
  };

  renderMessages = () => {
    const { messages } = this.state;
    return messages.map(({ id, created_at, role, content }) => {
      const { value } = content[0].text;
      const timestamp = new Date(created_at * 1000).toLocaleString();
      const isUser = role === 'user';
      const displayRole = isUser ? 'User' : 'Vinnie AI';

      return (
        <div key={id} className={`message ${isUser ? 'user-message' : 'assistant-message'}`}>
          <div className="message-role">
            <span>{displayRole}</span> - <span className="timestamp">{timestamp}</span>
          </div>
          <div className="message-content">{value}</div>
        </div>
      );
    });
  };

  render() {
    const { isExpanded } = this.state;

    return (
      <div className={`ai-thread-view ${isExpanded ? 'expanded' : 'collapsed'}`}>
        <div className="thread-messages">
          {isExpanded && <div>{this.renderMessages()}</div>}
          <div ref={this.messageEndRef} className="message-end"></div>
        </div>
      </div>
    );
  }
}

AIThreadView.propTypes = {
  deal: PropTypes.instanceOf(Deal).isRequired,
};

export default AIThreadView;
