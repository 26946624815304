import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';

import API from '@root/ApiClient';

export function BartDebug({ open, userID, dealID }) {
  const [messages, setMessages] = useState([]);
  const containerRef = useRef(null);

  const loadThreadMessages = async () => {
    const res = await API.call('bart', {
      action: 'LOAD_THREAD_MESSAGES',
      userID,
      dealID,
    });
    // TODO: error handling!

    setMessages(res.messages);
  };

  const renderMessages = () => {
    return messages.map(({ id, created_at, role, content }) => {
      const text = content?.[0]?.text?.value;
      if (!text) return undefined;

      const displayRole = role === 'user' ? 'User' : 'Bart';
      const roleColor = role === 'user' ? '#61afef' : '#c678dd';
      const timestamp = new Date(created_at * 1000).toLocaleString();

      return (
        <div
          key={id}
          style={{
            borderLeft: '3px solid',
            borderLeftColor: roleColor,
            paddingLeft: '10px',
            marginBottom: '16px',
            fontSize: '11px',
          }}
        >
          <div style={{ marginBottom: '4px' }}>
            <span style={{ fontSize: '13px', fontWeight: 'bold', color: roleColor }}>{displayRole}</span> -{' '}
            <span style={{ color: 'hsl(109, 100.00%, 81.40%)' }}>{timestamp}</span>
          </div>
          <div style={{ whiteSpace: 'pre-wrap' }}>{text}</div>
        </div>
      );
    });
  };

  useEffect(() => {
    if (!open) return;
    loadThreadMessages();
  }, [open]);

  useEffect(() => {
    const el = containerRef.current;
    if (!el) return;
    el.scrollTop = el.scrollHeight;
  }, [messages]);

  return (
    <div
      style={{
        position: 'fixed',
        left: open ? '0px' : '-400px',
        top: 0,
        height: '100%',
        width: '400px',
        transition: 'left 0.3s ease',
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        color: '#abb2bf',
      }}
    >
      <div ref={containerRef} style={{ overflow: 'auto', height: '100%', padding: '10px' }}>
        {renderMessages()}
      </div>
    </div>
  );
}
