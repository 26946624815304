import React, { useEffect, useState } from 'react';
import { useRef } from 'react';

import { Switch } from '@components/dmp';

import API from '@root/ApiClient';

import { BartDebug } from './BartDebug';
import { debugObj } from './BartShared';

export default function BartChat(props) {
  const { user, deal } = props.context;
  const [chatMessages, setChatMessages] = useState([]);
  const [renderedMessages, setRenderedMessages] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [debugOpen, setDebugOpen] = useState(false);

  const textareaRef = useRef(null);

  const useVectorStore = user.threads && user.threads[deal.dealID] ? user.threads[deal.dealID].useVectorStore : false;
  const threadID = user.threads && user.threads[deal.dealID] ? user.threads[deal.dealID].threadID : null;

  useHotkey({ key: 'd', onTriggered: () => setDebugOpen((lastVal) => !lastVal) });
  const [dimensions, handleResize] = useResizer();

  const clearThread = async () => {
    try {
      setIsLoading(true);
      await API.call('bart', {
        action: 'CLEAR_THREAD',
        threadID,
      });
      //Step 3: remove all messages
      setChatMessages([]);
      renderMessages([]);
    } catch (error) {
      console.error('Failed to initialize thread:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const initThread = async () => {
    try {
      setIsLoading(true);
      if (!threadID) {
        await API.call('bart', {
          action: 'START_THREAD',
          context: props.context,
          useVectorStore,
        });
      } else {
        const { messages } = await API.call('bart', {
          action: 'RETRIEVE_THREAD_MESSAGES',
          threadID,
        });

        const msgs = _.compact(
          _.map(messages.data.reverse(), ({ content, role }) => {
            const contentValue = content[0].text.value;
            if (!contentValue.includes('Deal:') && !contentValue.includes('User:'))
              return { role, content: contentValue };
          })
        );

        setChatMessages(msgs);
        renderMessages(msgs);
      }
    } catch (error) {
      console.error('Failed to initialize thread:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleThread = async () => {
    try {
      setIsLoading(true);
      //Step 1: Delete active thread.
      await API.call('bart', {
        action: 'DELETE_THREAD',
        threadID,
        context: props.context,
      });

      //Step 2: Restart the chat with the new option
      await API.call('bart', {
        action: 'START_THREAD',
        context: props.context,
        useVectorStore: !useVectorStore,
      });
      //Step 3: remove all messages
      setChatMessages([]);
      renderMessages([]);
    } catch (error) {
      console.error('Failed to toggle thread retrieval method', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initThread();
  }, []);

  useEffect(() => {
    const el = textareaRef.current;
    if (!el) return;
    el.scrollTop = el.scrollHeight;
  }, [renderedMessages]);

  const renderMessages = (messages) => {
    let txt = '';
    for (const msg of messages) {
      switch (msg.role) {
        case 'user':
          txt += 'USER: ';
          break;
        case 'assistant':
          txt += 'BART: ';
          break;
        default:
          continue;
      }
      txt += msg.content + '\n\n';
    }
    setRenderedMessages(txt);
  };

  const sendUserMessage = async (message) => {
    setIsLoading(true);

    const messages = [...chatMessages];

    messages.push({
      role: 'user',
      content: message,
    });

    setChatMessages(messages);
    renderMessages(messages);

    const onStream = (data) => {
      //Todo but this will be a stream of text from the assistant
      if (messages[messages.length - 1].role !== 'assistant') {
        messages.push({ role: 'assistant', content: data });
      } else {
        messages[messages.length - 1].content += data;
      }
      setChatMessages(messages);
      renderMessages(messages);
    };

    await API.stream(
      'bartStream',
      {
        action: 'RUN_THREAD',
        threadID,
        message,
        context: props.context,
        useVectorStore,
      },
      onStream
    );
    setIsLoading(false);
  };

  const listRuns = async () => {
    const res = await API.call('bart', { action: 'LIST_RUNS', threadID });
    if (res.success) {
      let runs = res.runs.slice(0, 3).map((run) => {
        return { ...run, created_at: new Date(run.created_at * 1000).toLocaleString() };
      });
      debugObj('runs', runs);
    }
  };

  return (
    <div
      style={{
        zIndex: 99999,
        position: 'fixed',
        left: debugOpen ? '400px' : 0,
        bottom: 0,
        width: dimensions.width,
        height: dimensions.height,
        transition: 'left 0.3s ease',
        padding: 10,
        backgroundColor: 'skyblue',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TitleBar />

      <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', width: '100%' }}>
        <div style={{ flexGrow: '1' }}></div>
        {/* <Switch checked={useVectorStore} onChange={toggleThread} size="small" disabled={isLoading}>
          Use Vector Store (file_search)
        </Switch> */}
        {/* <button onClick={listRuns}>List Runs</button> */}
        {/* <button onClick={clearThread}>Clear Thread</button> */}
      </div>

      <textarea
        ref={textareaRef}
        style={{
          fontSize: '11pt',
          flexGrow: '1',
          backgroundColor: 'hsl(210, 100%, 20%)',
          color: 'hsl(210, 100%, 85%)',
        }}
        value={renderedMessages}
        readOnly
      />

      <UserMessageInput onSend={sendUserMessage} isLoading={isLoading} />

      <ResizeHandle onResize={handleResize} />

      <BartDebug open={debugOpen} userID={user.id} dealID={deal.dealID} />
    </div>
  );
}

//----------------------------------------------------------------------------------------
// BartChat Subcomponents
//----------------------------------------------------------------------------------------
function TitleBar() {
  return (
    <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>
      Bart Chat - 🚧 ⚠️FOR INTERNAL USE ONLY: DO NOT DEMO⚠️ 🚧
    </div>
  );
}

function UserMessageInput({ onSend, isLoading }) {
  const [messageText, setMessageText] = useState('');

  const handleKeyDown = (e) => {
    if (!isLoading && e.key === 'Enter' && messageText.length > 0) {
      onSend(messageText);
      setMessageText('');
    }
  };

  return (
    <input
      type="text"
      placeholder={isLoading ? 'Thinking...' : 'Message Bart'}
      value={messageText}
      onChange={(e) => setMessageText(e.target.value)}
      onKeyDown={handleKeyDown}
      style={{ backgroundColor: isLoading ? 'hsl(0,0%,78%)' : 'white' }}
    ></input>
  );
}

function ResizeHandle({ onResize }) {
  return (
    <div
      style={{
        position: 'absolute',
        right: '0px',
        top: '0px',
        backgroundColor: 'rgba(0,0,0,20%)',
        width: '20px',
        height: '20px',
        cursor: 'nesw-resize',
      }}
      onMouseDown={onResize}
    ></div>
  );
}

//----------------------------------------------------------------------------------------
// Custom Hooks
//----------------------------------------------------------------------------------------
function useHotkey({ key, onTriggered }) {
  const handleKeyDown = (event) => {
    if (event.ctrlKey && event.key === key) {
      event.preventDefault();
      onTriggered();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
}

function useResizer() {
  const [dimensions, setDimensions] = useState(() => {
    const saved = localStorage.getItem('bart.window.dimensions');
    return saved ? JSON.parse(saved) : { width: 900, height: 400 };
  });

  useEffect(() => {
    localStorage.setItem('bart.window.dimensions', JSON.stringify(dimensions));
  }, [dimensions]);

  const handleResize = (e) => {
    e.preventDefault();
    const startWidth = dimensions.width;
    const startHeight = dimensions.height;
    const startX = e.clientX;
    const startY = e.clientY;

    const onMouseMove = (moveEvent) => {
      const minWidth = 360;
      const minHeight = 270;
      const newWidth = Math.max(minWidth, startWidth + moveEvent.clientX - startX);
      const newHeight = Math.max(minHeight, startHeight - (moveEvent.clientY - startY));
      setDimensions({ width: newWidth, height: newHeight });
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  return [dimensions, handleResize];
}
